import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import Button from "components/Button"

const SingleCourse = ({ course }) => {
  const {
    duration,
    durationopt = "",
    listImg,
    price,
    priceopt = "",
    title,
  } = course.acfCourses

  const to_shrink = title.length > 70

  return (
    <div className="col-lg-4 col-sm-6 col-12">
      <Link to={`/szkolenia/szkolenia-stacjonarne/${course.slug}/`}>
        <div className="stationary-course">
          <div className="stationary-course__img">
            <img src={listImg?.sourceUrl} alt={listImg?.altText} />
          </div>
          <div className="stationary-course__content">
            <p
              className={`stationary-course__title${
                to_shrink ? " shrink" : ""
              }`}
            >
              {title}
            </p>
            <div className="stationary-course__details">
              <div className="stationary-course__info">
                <img
                  src={require("assets/icons/calendar.svg").default}
                  alt=""
                />
                <p className="stationary-course__info-type">
                  Czas trwania kursu
                </p>
                <div className="stationary-course__price">
                  <p>{duration}</p>
                  {durationopt && <p>{durationopt}</p>}
                </div>
              </div>
              <div className="stationary-course__info">
                <img src={require("assets/icons/coins.svg").default} alt="" />
                <p className="stationary-course__info-type">Cena za kurs</p>
                <div className="stationary-course__price">
                  <p>{price} zł</p>
                  {priceopt && <p>{priceopt} zł</p>}
                </div>
              </div>
            </div>
            <Button type="button" className="button-dark-green">
              Chcę dowiedzieć się więcej
            </Button>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default SingleCourse
