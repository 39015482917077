import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import {
  StationaryInfo,
  StationaryList,
} from "page_components/stationary-courses"

const StationaryCourses = ({ data }) => {
  const [coursesList, setCoursesList] = useState(1)

  const breadcrumbs_data = [
    {
      name: "Szkolenia",
      href: "/szkolenia/",
    },
    {
      name: "Szkolenia stacjonarne",
      href: "/szkolenia/szkolenia-stacjonarne/",
    },
  ]
  const single_courses = data?.allWpCourse?.nodes?.filter(
    course => Number(course?.acfCourses?.courseType) === 1
  )
  const packages = data?.allWpCourse?.nodes?.filter(
    course => Number(course?.acfCourses?.courseType) === 2
  )
  const courses_to_show = coursesList === 1 ? single_courses : packages

  return (
    <Layout
      seo={{
        title: "Szkolenia stacjonarne",
        description:
          "Zapraszam Cię do zapoznania się z ofertą moich szkoleń i obiecuję, że zrobie wszystko, aby jak najlepiej przygotować Cię do pracy ze stopami. Sprawdź!",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Szkolenia stacjonarne" />
      <StationaryInfo />
      <StationaryList
        coursesList={coursesList}
        setCoursesList={setCoursesList}
        courses={courses_to_show}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpCourse {
      nodes {
        id
        acfCourses {
          courseType
          duration
          durationopt
          listImg {
            altText
            sourceUrl
          }
          price
          priceopt
          title
        }
        slug
      }
    }
  }
`

export default StationaryCourses
