import "./styles.scss"

import React from "react"

const StationaryInfo = () => {
  return (
    <section className="stationary-info">
      <div className="container">
        <div className="d-flex flex-column-reverse flex-lg-row">
          <div className="col-lg-6 col-12">
            <div className="stationary-info__content">
              <p className="stationary-info__text">
                Oferta szkoleniowa skierowana jest do osób, które chcą rozpocząć
                pracę jako podolog, ale także dla tych, którzy już pracują w
                zawodzie i pragną się rozwijać i poznawać różne techniki pracy z
                różnymi problemami ze stopami.
              </p>
              <p className="stationary-info__details">Sposób nauki</p>
              <ul>
                <li>
                  abyś jak najefektywniej wykorzystał czas - teoria jest
                  przekazana w wygodnej formie online
                </li>
                <li>
                  praktyczna część szkolenia jest realizowana u mnie w gabinecie
                  w terminie ustalonym indywidualnie
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="stationary-info__img">
              <img
                src={
                  require("assets/images/courses/kurs_stacjonarny.jpg").default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StationaryInfo
