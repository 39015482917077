import "./styles.scss"

import React from "react"

import Button from "components/Button"
import SingleCourse from "../SingleCourse"

const StationaryList = ({ coursesList, setCoursesList, courses }) => {
  return (
    <section className="stationary-list">
      <div className="container">
        <div className="stationary-list__buttons">
          <button
            type="button"
            className={`stationary-list__button${
              coursesList === 1 ? " active" : ""
            }`}
            onClick={() => setCoursesList(1)}
          >
            Pojedyncze szkolenia
          </button>
          <button
            type="button"
            className={`stationary-list__button${
              coursesList === 2 ? " active" : ""
            }`}
            onClick={() => setCoursesList(2)}
          >
            Pakiety szkoleń
          </button>
        </div>
        <div className="stationary-list__items">
          <div className="row">
            {courses.map(course => (
              <SingleCourse key={course.id} course={course} />
            ))}
          </div>
        </div>
        <div className="stationary-list__cta">
          <p>Jesteś zainteresowany szkoleniem?</p>
          <Button type="button" className="button-dark-green" to="/kontakt/">
            Skontaktuj się z nami
          </Button>
        </div>
      </div>
    </section>
  )
}

export default StationaryList
